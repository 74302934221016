<template>
  <b-row class="my-3 mx-2">
    <b-col cols="12">
      <b-row>
        <b-col
          cols="12"
          md="4"
          offset-md="2"
        >
          <!-- metting header -->
          <div class="meetup-header d-flex justify-content-center">
            <div class="my-auto">
              <h3 class="mb-2 text-center">
                {{ $t('idy.footer.contact') }}
              </h3>
            </div>
          </div>
          <!--/ metting header -->

          <div class="meetup-header d-flex justify-content-center">
            <b-row class="justify-content-center mb-3">
              <b-col cols="12">
                <!-- media -->
                <b-media
                  v-for="media in mediaData"
                  :key="media.id"
                  no-body
                >
                  <b-media-body>
                    <h6 class="mb-0">
                      {{ media.title }}
                    </h6>
                    <small>{{ media.subtitle }}</small>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <!-- metting header -->
          <div class="meetup-header d-flex justify-content-center">
            <div class="my-auto">
              <h3 class="mb-2 text-center">
                {{ $t('idy.footer.terms') }}
              </h3>
            </div>
          </div>
          <!--/ metting header -->

          <div class="meetup-header d-flex justify-content-center">
            <b-row class="d-flex justify-content-center mb-3">
              <b-col cols="12">
                <!-- media -->
                <b-media
                  no-body
                >
                  <b-media-body>
                    <b-link
                      @click="secretModal = !secretModal"
                    >
                      <h6 class="mb-1 text-center">
                        {{ $t('idy.footer.privacyPolicy') }}
                      </h6>
                    </b-link>
                  </b-media-body>
                </b-media>
                <b-media
                  no-body
                >
                  <b-media-body>
                    <b-link
                      @click="shipmentModal = !shipmentModal"
                    >
                      <h6 class="mb-1 text-center">
                        {{ $t('idy.footer.delivery') }}
                      </h6>
                    </b-link>
                  </b-media-body>
                </b-media>
                <b-media
                  no-body
                >
                  <b-media-body>
                    <b-link
                      @click="distanceModal = !distanceModal"
                    >
                      <h6 class="mb-1 text-center">
                        {{ $t('idy.footer.distance') }}
                      </h6>
                    </b-link>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </div>

          <!-- metting header -->
          <div class="meetup-header d-flex justify-content-center">
            <div class="my-auto">
              <!-- <h3 class="mb-2 text-center">
                Sosyal Medya
              </h3> -->
              <b-row class="mb-2">
                <b-col>
                  <b-link
                    href="https://instagram.com/simprame"
                    target="_blank"
                  >
                    <feather-icon
                      icon="InstagramIcon"
                      size="25"
                    />
                  </b-link>
                </b-col>
                <b-col>
                  <b-link
                    href="#"
                    target="_blank"
                  >
                    <feather-icon
                      icon="TwitterIcon"
                      size="25"
                    />
                  </b-link>
                </b-col>
                <b-col>
                  <b-link
                    href="#"
                    target="_blank"
                  >
                    <feather-icon
                      icon="LinkedinIcon"
                      size="25"
                    />
                  </b-link>
                </b-col>
              </b-row>
            </div>
          </div>
          <!--/ metting header -->
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      sm="12"
      md="9"
      lg="9"
      xl="9"
      class="mb-2"
    >
      <p class="mb-0">
        <span class="text-left">
          <b-link
            href="#"
            target="_blank"
          >Simpra</b-link>
          © {{ new Date().getFullYear() }}
          <span> {{ $t('idy.footer.allRightReserved') }}</span>
        </span>
      </p>
    </b-col>
    <b-modal
      id="modal-shipment-footer"
      v-model="shipmentModal"
      title=""
      scrollable
      hide-footer
      size="lg"
    >
      <b-card-text class="pb-2 mx-2">
        <p><span style="font-size:11pt;">ВАРИАНТЫ ДОСТАВКИ</span></p>
        <p><span style="font-size:11pt;">Самовывоз(Сатпаева 90а) &mdash; БЕСПЛАТНО</span></p>
        <p><span style="font-size:11pt;">Почта по РК &mdash; БЕСПЛАТНО (от 3 дней)</span></p>
        <p><span style="font-size:11pt;">Курьерская доставка &laquo;до двери&raquo; по Казахстану &mdash; 2000 тенге (от 3 дней)</span></p>
        <p><span style="font-size:11pt;">Доставка по миру &mdash; по запросу (от 5 дней)</span></p>
        <p><span style="font-size:11pt;">ПРЕДУПРЕЖДЕНИЕ!</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">Персонализированные товары не подлежат возврату или обмену.</span></p>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-secret-footer"
      v-model="secretModal"
      title=""
      scrollable
      hide-footer
      size="lg"
    >
      <b-card-text class="pb-2 mx-2">
        <p><span style="font-size:11pt;">Правила защиты информации о пользователях сервиса Simpra</span></p>
        <p><span style="font-size:11pt;">1. Общие положения</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.1. Настоящие Правила являются официальным документом ИП &laquo;Simpra&raquo; (далее &ndash; Компания) и определяют порядок обработки и защиты информации о физических лицах, пользующихся услугами сервиса Simpra (далее &ndash; Сервис), доступного по адресу www.simpra.kz и в мобильных приложениях Simpra (далее &ndash; Пользователи).</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.2. Целью настоящих Правил является обеспечение надлежащей защиты информации о пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях Сервиса, регулируются настоящими Правилами, иными официальными документами Компании и действующим законодательством РК.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.4. Действующая редакция Правил, являющихся публичным документом, доступна любому пользователю сети Интернет при переходе по ссылке https://simpra.kz/privacy-policy/kz. Компания вправе вносить изменения в настоящие Правила. При внесении изменений в Правила Компания уведомляет об этом Пользователей путем размещения новой редакции Правил по постоянному адресу https://simpra.kz/privacy-policy/kz не позднее, чем за 10 дней до вступления в силу соответствующих изменений. Предыдущие редакции Правил хранятся в архиве документации Компании.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.5. Настоящие Правила разработаны и используются в соответствии с Пользовательским соглашением сервиса Simpra, размещенным по адресу http://simpra/agreement/kz. В случае наличия противоречий между настоящими Правилами и иными официальными документами Компании применению подлежат настоящие Правила.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.6. Регистрируясь и используя Сервис, Пользователь выражает свое согласие с условиями настоящих Правил.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.7. В случае несогласия Пользователя с условиями настоящих Правил использование Сервиса должно быть немедленно прекращено.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2. Условия пользования Сервисом</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2.1. Оказывая услуги по использованию Сервиса, Компания, действуя разумно и добросовестно, считает, что Пользователь:</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию и использовать Сервис;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">указывает достоверную информацию о себе в объемах, необходимых для пользования Сервисом;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">осознает, что информация, размещаемая Пользователем о себе, может становиться доступной для других Пользователей Сервиса и пользователей Интернета, может быть скопирована и распространена такими пользователями;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">осознает, что некоторые виды информации, переданные им другим Пользователям, не могут быть удалены самим Пользователем;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">ознакомлен с настоящими Правилами, выражает свое согласие с ними и принимает на себя указанные в них права и обязанности.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2.2. Компания не проверяет достоверность получаемой (собираемой) информации о пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения Компанией обязательств перед пользователем.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">3. Цели обработки информации</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">3.1. Компания осуществляет обработку информации о Пользователях, в том числе их персональных данных, в целях выполнения обязательств Компанией перед Пользователями в отношении использования Сервиса.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4. Состав информации о пользователях</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.1. Персональные данные Пользователей включают в себя:</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.1.1. предоставляемые Пользователями при создании визитных карточек в Сервисе: имя, фамилия, отчество, номера мобильного, домашнего и рабочего телефонов, адрес электронной почты, дата рождения, домашний адрес, ссылки на профили в социальных сетях, место работы и должность.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.1.2. дополнительно предоставляемые Пользователями по запросу Компании в целях исполнения Компанией обязательств перед Пользователями, вытекающих из договора на оказание услуг Сервиса. Компания вправе, в частности, запросить у Пользователя копию документа, удостоверяющего личность, либо иного документа, содержащего имя, фамилию, фотографию Пользователя, а также иную дополнительную информацию, которая, по усмотрению Компании, будет являться необходимой и достаточной для идентификации такого Пользователя и позволит исключить злоупотребления и нарушения прав третьих лиц.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.2. Компания может также обрабатывать иную информацию о Пользователях, которая включает в себя:</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.2.1. дополнительные данные, получаемые при доступе к Сервису, включающие в себя данные о технических средствах (устройствах), технологическом взаимодействии с Сервисом (в т.ч. IP-адрес хоста, вид операционной системы пользователя, тип браузера, географическое положение, поставщик услуг Интернета, данные из адресной книги, данные, полученные в результате доступа к камере, микрофону и т.п. устройств), и последующих действиях Пользователя в Сервисе.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.2.2. информация, автоматически получаемая при доступе к Сервису с использованием закладок (cookies).</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5. Обработка информации о пользователях</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.1. Обработка персональных данных осуществляется на основе принципов:</span></p>
        <p><span style="font-size:11pt;">а) законности целей и способов обработки персональных данных;</span></p>
        <p><span style="font-size:11pt;">б) добросовестности;</span></p>
        <p><span style="font-size:11pt;">в) соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Компании;</span></p>
        <p><span style="font-size:11pt;">г) соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;</span></p>
        <p><span style="font-size:11pt;">д) недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих персональные данные.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.1.1. Условия и цели обработки персональных данных</span></p>
        <p><span style="font-size:11pt;">Компания осуществляет обработку персональных данных пользователя в целях исполнения договора между Компанией и Пользователем на оказание услуг Сервиса (п. 8.1 Пользовательского соглашения сервиса Simpra).&nbsp;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.1.2. Сбор персональных данных</span></p>
        <p><span style="font-size:11pt;">Сбор персональных данных Пользователя осуществляется при создании и редактировании визитных карточек Пользователя в Сервисе.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.1.3. Хранение и использование персональных данных</span></p>
        <p><span style="font-size:11pt;">Персональные данные пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.1.4. Передача персональных данных</span></p>
        <p><span style="font-size:11pt;">Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими Правилами.</span></p>
        <p><span style="font-size:11pt;">При указании пользователя или при наличии согласия пользователя возможна передача персональных данных пользователя третьим лицам-контрагентам Компании с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации.</span></p>
        <p><span style="font-size:11pt;">Предоставление персональных данных Пользователей по запросу государственных органов (органов местного самоуправления) осуществляется в порядке, предусмотренном законодательством.</span></p>
        <p><br></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.1.5. Уничтожение персональных данных</span></p>
        <p><span style="font-size:11pt;">Персональные данные пользователя уничтожаются при:</span></p>
        <p><span style="font-size:11pt;">&ndash; самостоятельном удалении Пользователем данных из своих визитных карточек;</span></p>
        <p><span style="font-size:11pt;">&ndash; удалении Компанией информации, размещаемой Пользователем, в случаях, установленных Пользовательским соглашением сервиса Simpra (п.п. 2.8 и 3.2).</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.2. Размещая информацию в своих визитных карточках, в том числе свои персональные данные, Пользователь осознает и соглашается с тем, что указанная информация может быть доступна другим пользователям сети Интернет.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6. Права и обязанности пользователей</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.1. Пользователи вправе:</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.1.1. осуществлять доступ к информации о себе посредством мобильных приложений Simpra;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.1.2. самостоятельно вносить изменения и исправления в информацию о себе в визитных карточках в Системе, при условии, что такие изменения и исправления содержат актуальную и достоверную информацию;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.1.3. удалять информацию о себе из визитных карточек в Системе;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.1.4. требовать от Компании уточнения своих персональных данных, их блокирования или уничтожения в случае, если такие данные являются неполными, устаревшими, недостоверными, незаконно полученными или не являются необходимыми для заявленной цели обработки и если невозможно самостоятельно выполнить действия, предусмотренные п.п. 6.1.2. и 6.1.3. настоящих Правил;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.1.5. на основании запроса получать от Компании информацию, касающуюся обработки его персональных данных.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">7. Меры по защите информации о Пользователях</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">7.1. Компания принимает технические и организационно-правовые меры в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8. Ограничение действия Правил</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8.1. Действие настоящих Правил не распространяется на действия и интернет-ресурсы третьих лиц.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8.2. Компания не несет ответственности за действия третьих лиц, получивших в результате использования Интернета или Сервиса доступ к информации о Пользователе, которую он разместил в своих визитных карточках, за последствия использования информации, которая, в силу природы Сервиса, доступна любому пользователю сети Интернет. Компания рекомендует Пользователям ответственно подходить к решению вопроса об объеме информации о себе, размещаемой в Сервисе.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">9. Обращения пользователей</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">9.1. Пользователи вправе направлять Компании свои запросы, в том числе запросы относительно использования их персональных данных, предусмотренные п. 6.1.5 настоящих Правил, в письменной форме по адресу: Казахстан, Алматы, Улица Сатпаева 90а, офис 208 или в форме электронного документа, подписанного квалифицированной электронной подписью в соответствии с законодательством РK, по адресу электронной почты: info@simpra.kz.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">9.2. Запрос, направляемый пользователем, должен содержать следующую информацию:</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">номер основного документа, удостоверяющего личность пользователя или его представителя;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">сведения о дате выдачи указанного документа и выдавшем его органе;</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">сведения, подтверждающие участие пользователя в отношениях с оператором (в частности, адрес электронной почты, использовавшийся для регистрации в Системе);</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">подпись пользователя или его представителя.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">9.3. Компания обязуется рассмотреть и направить ответ на поступивший запрос пользователя в течение 30 дней с момента поступления обращения.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">9.4. Вся корреспонденция, полученная Компанией от пользователей (обращения в письменной или электронной форме), относится к информации ограниченного доступа и не разглашается без письменного согласия Пользователя. Персональные данные и иная информация о Пользователе, направившем запрос, не могут быть без специального согласия Пользователя использованы иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных законодательством.</span></p>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-distance-footer"
      v-model="distanceModal"
      title=""
      scrollable
      hide-footer
      size="lg"
    >
      <b-card-text class="pb-2 mx-2">
        <p><span style="font-size:11pt;">1. Общие положения</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.1. ИП &laquo;Simpra&raquo; (далее &mdash; Компания) предлагает пользователю сети Интернет (далее &mdash; Пользователь) использовать свой сервис Simpra, доступный по адресу www.simpra.kz и в мобильных приложениях Simpra (далее &mdash; Сервис), на условиях, изложенных в настоящем Пользовательском соглашении (далее &mdash; Соглашение). Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном п. 1.2 Соглашения.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.2. Подтвердив адрес электронной почты для использования в Сервисе и/или начиная использовать Сервис (с момента установки мобильного приложения), Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не вправе использовать Сервис. В случае если Компанией были внесены какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.3 Соглашения, с которыми Пользователь не согласен, он обязан прекратить использование Сервиса.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.3. Настоящее Соглашение может быть изменено Компанией без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем пункте адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция Соглашения всегда находится на странице по адресу: https://simpra/agreement/kz. Пользователь обязан самостоятельно и регулярно проверять наличие изменений в настоящем Соглашении.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">1.4. Сервис предлагает Пользователю возможность создания электронных визитных карточек. Все существующие на данный момент функции Сервиса, а также любое развитие их и/или добавление новых является предметом настоящего Соглашения.</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2. Учетная запись Пользователя</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2.1. Для того, чтобы воспользоваться Сервисом, Пользователю необходимо пройти процедуру подтверждения адреса электронной почты, в результате которой для Пользователя будет создана уникальная учетная запись.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2.2. Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием Сервиса под учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам. При этом все действия в рамках или с использованием Сервиса под учетной записью Пользователя считаются произведенными самим Пользователем, за исключением случаев, когда Пользователь в порядке, предусмотренном п. 2.3 настоящего Соглашения, уведомил Компанию о несанкционированном доступе к Сервису с использованием учетной записи Пользователя.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2.3. Пользователь обязан немедленно уведомить Компанию о любом случае несанкционированного (не разрешенного Пользователем) доступа к Сервису с использованием учетной записи Пользователя. Компания не отвечает за возможную потерю или порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения Пользователем положений этой части Соглашения.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2.7. Используя свою учетную запись, Пользователь не вправе воспроизводить, повторять и копировать какие-либо части Сервиса или доступ к ним, кроме тех случаев, когда Пользователь получил такое разрешение от Компании.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">2.8. Компания вправе заблокировать или удалить учетную запись Пользователя, а также запретить доступ с использованием какой-либо учетной записи к Сервису, и удалить любой контент без объяснения причин, в том числе в случае нарушения Пользователем условий Соглашения.</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">3. Контент Пользователя</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">3.1. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем контента требованиям действующего законодательства, включая ответственность перед третьим лицами в случаях, когда размещение Пользователем того или иного контента или содержание контента нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает на принадлежащие им нематериальные блага.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">3.2. Пользователь признает и соглашается с тем, что Компания не обязана просматривать контент любого вида, размещаемый и/или распространяемый Пользователем посредством Сервиса, а также то, что Компания имеет право (но не обязанность) по своему усмотрению отказать Пользователю в размещении и/или распространении им контента или удалить любой контент, который доступен посредством Сервиса. Пользователь осознает и согласен с тем, что он должен самостоятельно оценивать все риски, связанные с использованием контента, включая оценку надежности, полноты или полезности этого контента.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">3.3. Пользователь осознает и соглашается с тем, что технология работы Сервиса может потребовать копирование (воспроизведение) контента Пользователя Компанией, а также переработки его Компанией для соответствия техническим требованиям Сервиса.</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4. Условия использования Сервиса</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.1. Сервис предоставляет Пользователю возможность создания электронных визитных карточек с возможностью передачи их путем демонстрации QR-кода.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.2. На Сервисе запрещается совершение действий, направленных на введение других пользователей в заблуждение, в том числе запрещено:</span></p>
        <p><span style="font-size:11pt;">4.2.1. используя доступ к Сервису, выдавать себя за другое лицо;</span></p>
        <p><span style="font-size:11pt;">4.2.2. размещать информацию в полях форм, которые не предназначены для данного типа информации;</span></p>
        <p><span style="font-size:11pt;">4.2.3. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям;</span></p>
        <p><span style="font-size:11pt;">4.2.4. нарушать нормальную работу веб-сайта и Сервиса Компании;</span></p>
        <p><span style="font-size:11pt;">4.2.5. размещать ссылки на ресурсы сети, содержание которых противоречит действующему законодательству РK.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.3. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием Сервиса, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании Сервиса.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.4. Пользователь соглашается с тем, что размещаемая им на Сервисе информация может быть доступна для других пользователей.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">4.5. Пользователь самостоятельно обеспечивает наличие оборудования, соответствующего техническим требованиям для использования Сервиса и доступа к сети Интернет.</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5. Исключительные права на содержание Сервиса и контент</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.1. Все объекты, доступные при помощи Сервиса, в том числе</span></p>
        <p><span style="font-size:11pt;">элементы дизайна, текст, графические изображения и другие объекты (далее &mdash; содержание Сервиса), а также любой контент, размещенный на Сервисе, являются объектами исключительных прав Компании, Пользователей и других правообладателей.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">5.2. Использование контента, а также каких-либо иных элементов Сервиса возможно только в рамках функционала, предлагаемого Сервисом. Никакие элементы содержания Сервиса, а также любой контент, размещенный на Сервисе, не могут быть использованы иным образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе и т. д.</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6. Отсутствие гарантий, ограничение ответственности</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.1. Сервис предоставляется &laquo;как есть&raquo;. Компания не принимает на себя никакой ответственности, в том числе за соответствие Сервиса целям Пользователя.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.2. Если при использовании Сервиса будут обнаружены ошибки, Компания предпримет меры для их исправления в максимально короткие сроки.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.3. Компания не инициирует и не контролирует размещение Пользователем любой информации в процессе использования Сервиса, не влияет на ее содержание и целостность, а также в момент размещения указанной информации не знает и не может знать нарушает ли она охраняемые законом права и интересы третьих лиц, международные договоры и действующее законодательство РK.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.4. За нарушение условий настоящего Соглашения наступает ответственность, предусмотренная законодательством РK.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.5. Компания не несет ответственности перед Пользователем за любой ущерб, любую потерю доходов, прибыли, информации или сбережений, связанных с использованием или с невозможностью использования Сервиса, в том числе в случае предварительного уведомления со стороны Пользователя о возможности такого ущерба, или по любому иску третьей стороны.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">6.6. Компания вправе заблокировать бесплатное использование Сервиса, предоставленное посредством скачивания бесплатного мобильного приложения в сети Интернет, в случае если такое использование осуществляется Пользователем не в личных, а в коммерческих целях организаций, кроме случаев использования бесплатного Сервиса совместно с приобретенными у Компании дополнительными смарт-аксессуарами (NFC/QR-карты/наклейки и т. п.).</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">7. Условие обработки и использования персональных данных Принимая условия настоящего Соглашения, Пользователь выражает свое согласие на:</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">7.1. Предоставление своих персональных данных, включающих фамилию, имя, отчество, адреса электронной почты, номера контактных телефонов, дату рождения, домашний адрес, информацию об организации, должность для их обработки Компанией, свободно, своей волей и в своем интересе. Цель обработки персональных данных:</span></p>
        <p><span style="font-size:11pt;">&mdash; Предоставление Пользователю права использования Сервиса;</span></p>
        <p><span style="font-size:11pt;">&mdash; Направление уведомлений, касающихся использование Сервиса;</span></p>
        <p><span style="font-size:11pt;">&mdash; Перечень действий с персональными данными, на которые Пользователь выражает свое согласие:</span></p>
        <p><span style="font-size:11pt;">сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, передача третьим лицам для указанных выше целей, а так же осуществление любых иных действий, предусмотренных действующим законодательством РK как неавтоматизированными, так и автоматизированными способами. Компания обязуется принимать все необходимые меры для защиты персональных данных Пользователя от неправомерного доступа или раскрытия. Настоящее согласие действует до момента его отзыва Пользователем путем направления соответствующего уведомления на электронный адрес info@simpra.kz.</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8. Иные положения</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8.1. Настоящее Соглашение представляет собой договор между Пользователем и Компанией относительно порядка использования Сервиса.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством РK. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством РK. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством РK.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8.3. Ничто в Соглашении не может пониматься как установление между Пользователем и Компанией агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8.4. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">8.5. Бездействие со стороны Компании в случае нарушения Пользователем либо иными пользователями положений Соглашения не лишает Компанию права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказа Компании от своих прав в случае совершения в последующем подобных либо сходных нарушений.</span></p>
        <p><br></p>
        <p><br></p>
        <p><span style="font-size:11pt;">9. Контактная информация</span></p>
        <p><br></p>
        <p><span style="font-size:11pt;">9.1. Обращения по всем вопросам принимаются по адресу: info@simpra.kz</span></p>
      </b-card-text>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BLink,
  BRow,
  BCol,
  BImg,
  BMedia,
  BMediaBody,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BImg,
    BMedia,
    BMediaBody,
    BModal,
  },
  data() {
    return {
      shipmentModal: false,
      secretModal: false,
      distanceModal: false,
      mediaData: [
        {
          id: 1,
          title: this.$t('idy.footer.address'),
          subtitle: 'Казахстан, Алматы, Улица Сатпаева 90а',
        },
        {
          id: 2,
          title: this.$t('idy.footer.techSupport'),
          subtitle: '+(775) 127 9898',
        },
        {
          id: 3,
          title: this.$t('idy.footer.marketing'),
          subtitle: '+(775) 127 9898',
        },
        {
          id: 4,
          title: this.$t('idy.footer.email'),
          subtitle: 'info@simpra.kz',
        },
      ],
    }
  },
}
</script>
