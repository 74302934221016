<template>
  <b-nav-item-dropdown
    ref="ddBasket"
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="basketListCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Sepetim
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ basketListCount }} Ürün
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="basketListCount > 0"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in basketList"
        :key="item.id"
      >
        <template #aside>
          <b-img
            :src="item.item_id === 1 ? require(`@/assets/images/cards/${item.color === 1 ? 'cardBlack' : (item.color === 2 ? 'cardWhite' : 'cardMultiColor')}-Back.png`) : require(`@/assets/images/tags/${item.color === 1 ? 'tagBlack' : (item.color === 2 ? 'tagWhite' : 'tagMultiColor')}.png`)"
            rounded
            width="62px"
          />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="deleteBasketItem(item.id)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.item_id === 1 ? 'idy Card' : 'idy Tag' }}
            </b-link>
          </h6>
          <template v-if="item.item_id === 1">
            <small class="cart-item-by">{{ item.specifications.front_text || '...' }}</small>
          </template>
          <template v-else>
            <small class="cart-item-by">{{ item.color === 1 ? 'Siyah' : (item.color === 2 ? 'Beyaz' : 'Gümüş Hologram') }}</small>
          </template>
        </div>

        <h5 class="cart-item-price">
          {{ Number(item.price).toLocaleString('tr-TR', {minimumFractionDigits: 2}) }}₺
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="basketListCount > 0"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Toplam:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ Number(totalAmount).toLocaleString('tr-TR', {minimumFractionDigits: 2}) }}₺
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        block
        @click="restoreBasket"
      >
        Sepeti Temizle
      </b-button>
      <!-- <router-link
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        to="/checkout"
        tag="button"
        variant="primary"
        block
      >
        Siparişi Tamamla
      </router-link> -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="goToBasket"
      >
        Sepete Git
      </b-button>
    </li>

    <p
      v-if="basketListCount === 0"
      class="m-0 p-1 text-center"
    >
      Sepetiniz şuan boş
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      basketList: 'eCommerce/getBasketList',
      basketListCount: 'eCommerce/getBasketListCount',
    }),
    totalAmount() {
      let total = 0
      this.basketList.forEach(i => { total += i.price })
      return total
    },
  },
  methods: {
    ...mapActions({
      addBasketItem: 'eCommerce/addBasketItem',
      deleteBasketItem: 'eCommerce/deleteBasketItem',
      restoreBasket: 'eCommerce/restoreBasket',
    }),
    goToBasket() {
      this.$refs.ddBasket.hide(true)
      this.$router.push('checkout')
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
