<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <template v-if="item.isChild">
        <div class="text-wrp">
          <b-img
            :src="require('@/assets/images/logo/simpra_logo.png')"
            alt="logo"
            style="width: 24px; height: 24px;"
          />
          <span class="menu-title text-truncate">{{ t(item.title) }}</span>
        </div>
      </template>
      <template v-else>
        <feather-icon :icon="item.icon || 'CircleIcon'" />
        <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      </template>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge, BImg } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
    BImg,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>

<style scoped>
.text-wrp {
  display: flex;
}
.text-wrp span {
  align-self: flex-end;
  margin-left: 5px;
}
</style>
