export default [
  {
    title: 'homepage',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'products',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Card',
        icon: 'CreditCardIcon',
        route: 'card-detail',
        isChild: true,
      },
      {
        title: 'PhoneCard',
        icon: 'CircleIcon',
        route: 'phone-card-detail',
        isChild: true,
      },
      {
        title: 'Tag',
        icon: 'CircleIcon',
        route: 'tag-detail',
        isChild: true,
      },
    ],
  },
  {
    title: 'contact',
    route: 'contacts',
    icon: 'MapPinIcon',
  },
]
